'use strict'

let dragged = false;
const mouseDraggedMinDelta = 5;
let mouseStartX, mouseStartY, lastMouseDelta;

jQuery(function () {
    $(document).on('mousedown', function(e){
        dragged = false;
        mouseStartX = e.clientX;
        mouseStartY = e.clientY;
    })
    $(document).on('mouseup', function(e){
        const mouseEndX = e.clientX;
        const mouseEndY = e.clientY;

        lastMouseDelta = Math.sqrt(Math.pow((mouseEndX - mouseStartX), 2) + Math.pow((mouseEndY - mouseStartY), 2))
        if(lastMouseDelta > mouseDraggedMinDelta){
            dragged = true;
        }
    })
})

export function isDrugged(){
    return dragged;
}