"use strict";
import './styles/style.scss';

import {
    setHeader,
    hidePreloader,
    setTopImage,
    hideLink,
    setScrollTop,
    hideMobileNav,
    toggleMobileNav,
    setMapCookies,
    setParams,
    initScroll,
    setMainNavHl
} from "./js/layoutfunctions.js";

import {isDrugged} from "./js/dragged.js";


import cookieconsent from 'cookieconsent';
import { get } from 'jquery';
const feather = require('feather-icons')



jQuery(function () {

    feather.replace();
    
    setHeader();

    if ($(".fs-slider").length < 1) {
        hidePreloader();
    }

    if ($("#top-image").length) {
        setTopImage($('#top-image'), 'img');
    }

    $(".hide-link-title").each(function () {
        hideLink($(this));
    });



    $(window).on('scroll', function () {
        setHeader();
        setScrollTop();
        setTopImage($('#top-image'), 'img');
    });

    $(window).on('resize', function () {
        setHeader();
        hideMobileNav();
    });


    //MENUICON
    $('.menu-icon').on('click', function (e) {
        e.preventDefault();
        toggleMobileNav();
    });

    //cookies
    $('.allowMap').on('click', function (e) {
        e.preventDefault();
        setMapCookies($(this).hasClass('true'));
    });


    //Main Nav

    $('.main-nav-ul').on('mouseenter', 'a', function(e){
        setMainNavHl($('.nav-holder'), $(this));
    });

    $('.nav-holder').on('mouseleave', function(e){
        setMainNavHl($('.nav-holder'), 0);
    });



    //consent
    var ctext = $('#cookies-text').html();
    var cok = $('#cookies-ok').html();
    window.addEventListener("load", function () {
        window.cookieconsent.initialise({
            "palette": {
                "popup": {
                    "background": "#f7f7f7"
                },
                "button": {
                    "background": "#D464B3"
                }
            },
            "position": "bottom-left",
            "content": {
                "message": ctext,
                "dismiss": cok,
                "link": "",
                "href": ""
            }
        })
    });


    //sections scrolls
    initScroll();

    $('.go-to').on('click', function(e){
        e.preventDefault()
        if(!isDrugged()){
            hideMobileNav();
            const alias = $(this).attr('data-attr-alias');
            setParams({alias: alias});
        }
    });
    
    $('#scroll-top').on('click', function (e) {
        e.preventDefault();
        setParams('');
        // $('html, body').animate({
        //     scrollTop: 0
        // }, 500, function(){
        //     setTimeout(function(){
        //         $('#scroll-top').removeClass('isvis');
        //     }, 100);
        // });
    });


    //sections video

    $('video').on('click', function(e){
        e.preventDefault();

        
        const elem = $(this).closest('.video-holder').find('.control').find('.playback');
        const videoId = elem.attr('data-attr-videoid');
        if(elem.hasClass('idle')){
            $('#'+videoId).get(0).play();
        }
        else{
            $('#'+videoId).get(0).pause();
        }
        elem.toggleClass('idle')
    })

    $('.video-control.playback').on('click', function(e){
        e.preventDefault();
        const videoId = $(this).attr('data-attr-videoid');
        if($(this).hasClass('idle')){
            $('#'+videoId).get(0).play();
        }
        else{
            $('#'+videoId).get(0).pause();
        }
        $(this).toggleClass('idle')
    })

    $('.video-control.sound').on('click', function(e){
        e.preventDefault();
        const videoId = $(this).attr('data-attr-videoid');
        const video = document.getElementById(videoId);
        if($(this).hasClass('idle')){
            video.volume = 1;
        }
        else{
            video.volume = 0;
        }
        $(this).toggleClass('idle')
    })

});
