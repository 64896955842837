'use strict'
export let elemPosPerc = (element, visArea = 100, type = 'full', omithh = false) => {
    const eh = element.outerHeight();
    const ot = element.offset().top;
    let hh;
    const wh = $(window).height();
    let st = $(window).scrollTop();

    if(st == 0){
        st = 0.0001;
    }
    if(omithh == true){
        hh = 0;
    }
    else{
        hh = $('header').outerHeight();
    }

    const posTop = ot / (st + wh - (wh - hh) * (100 - visArea) / 2 / 100); // top side of div. > 1 = below bottom of screen; 1- = above bottom side of the screen  // position of top part of element on screen selection: <1 - too dep, 1+ - on screen
    const posBottom = (st + hh + (wh - hh) * (100 - visArea) / 2 / 100) / (ot + eh); // bottom side of div. < 1 = below top side of screen; 1+ above top side of the screen //scroll position when bottom part of element disapears from screen < 1: above top, 1+ under top

    //console.log(element[0].className + ' pos top: ' + posTop + ', pos bottom: '+ posBottom)

    let perc = 0

    //if part of the div is on the screen - calcutete percentage
    if(posTop <= 1 && posBottom <= 1){
        let is = ot - wh + (wh - hh) * (100 - visArea) / 2 / 100; //scroll position when top part of element apears on screen
        if(type == 'top'){
            is = ot - hh - (wh - hh) * (100 - visArea) / 2 / 100;
        }
        const es = ot + eh - hh - (wh - hh) * (100 - visArea) / 2 / 100; //scroll position whon bottom part of element disapears from screen

        //console.log('init scroll: ' + is + ', end scroll: '+ es)
        const diff = es - is;
        const elpos = st - is;
        perc = elpos / diff;
        
        //console.log('ooops ' + perc)
    }
    
    //don't let go percentage below 0 or above 1
    if(posTop >= 1 || perc < 0 ){
        perc = 0;
    }
    if(posBottom >= 1 || perc > 1 ){
        perc = 1;
    }
    //console.log('perc: '+perc)
    return perc;
}